import { RecurrencePeriodTypes, ServiceCostType, ServiceStatusType } from "../service/model";
import { BillingTriggerType } from "../../types/api.model";
import { utils } from "../../utils";
import { isAutoApproval } from "../approval-method-date";
import { RoundPrices } from "../price-increase";
//
// type guards
//
export function isFixedPrice(cost) {
  return cost.pricingType === ServiceCostType.Fixed;
}
export function isVariablePrice(cost) {
  return cost.pricingType === ServiceCostType.Variable;
}
export function isRangePrice(cost) {
  return cost.pricingType === ServiceCostType.Range;
}
export function isManual(billing) {
  return [BillingTriggerType.OneTimeManual, BillingTriggerType.RepeatableManual].includes(billing.trigger);
}
export function isOneTime(billing) {
  return billing.trigger === BillingTriggerType.OnApproval || billing.trigger === BillingTriggerType.OneTimeManual || billing.trigger === BillingTriggerType.OneTimeDelivery;
}
export function isRecurring(billing) {
  return billing.trigger === BillingTriggerType.Ongoing || billing.trigger === BillingTriggerType.RepeatableManual;
}
export function supportsPriceIncrease(_ref) {
  let {
    billing
  } = _ref;
  return isRecurring(billing);
}
export function isAutomatic(billing) {
  return billing.trigger === BillingTriggerType.OnApproval || billing.trigger === BillingTriggerType.Ongoing;
}
export function isOnApproval(billing) {
  return billing.trigger === BillingTriggerType.OnApproval;
}
export function isOneTimeManual(billing) {
  return billing.trigger === BillingTriggerType.OneTimeManual;
}
export function isOneTimeDelivery(billing) {
  return billing.trigger === BillingTriggerType.OneTimeDelivery;
}
export function isOngoing(billing) {
  return billing.trigger === BillingTriggerType.Ongoing;
}
export function isRepeatableManual(billing) {
  return billing.trigger === BillingTriggerType.RepeatableManual;
}

//
// getters
//
export function id(service) {
  return service.id;
}
export function cost(service) {
  return service.cost;
}
export function discount(service) {
  return service.cost.discount;
}
export function billingTrigger(service) {
  return service.billing;
}
export function recurrencePeriod(billing) {
  return billing.recurrencePeriod;
}
export function billingDayOfMonth(service) {
  return isRecurring(service.billing) ? service.billing.billingDayOfMonth : null;
}
export function billingDayOfWeek(service) {
  return isRecurring(service.billing) ? service.billing.billingDayOfWeek : null;
}
export function maxChargesEnabled(service) {
  return isRecurring(service.billing) && service.billing.maxCharges != null ? service.billing.maxCharges.enabled : false;
}
export function maxCharges(service) {
  return isRecurring(service.billing) ? service.billing.maxCharges : null;
}
export function maxChargesLimit(service) {
  return isRecurring(service.billing) && service.billing.maxCharges != null ? service.billing.maxCharges.limit : null;
}
export function billingTriggerType(service) {
  return service.billing.trigger;
}
export function name(service) {
  return service.name;
}
export function price(_ref2) {
  let {
    cost
  } = _ref2;
  if (cost.pricingType === ServiceCostType.Fixed) {
    return cost.price;
  } else if (cost.pricingType === ServiceCostType.Variable) {
    return cost.price;
  } else {
    return cost.maxPrice;
  }
}
export function minPrice(service) {
  return service.cost.pricingType === ServiceCostType.Range ? service.cost.minPrice : null;
}
export function unitName(_ref3) {
  let {
    cost
  } = _ref3;
  if (cost.pricingType === ServiceCostType.Fixed || cost.pricingType === ServiceCostType.Range) {
    return null;
  } else {
    return cost.unitName;
  }
}
export function unitCap(_ref4) {
  let {
    cost
  } = _ref4;
  return isVariablePrice(cost) ? cost.unitCap : null;
}
export function finalPrice(_ref5, quantity) {
  let {
    cost
  } = _ref5;
  if (isRangePrice(cost)) {
    return utils.applyDiscount(cost.maxPrice, cost.discount);
  } else if (isFixedPrice(cost)) {
    return utils.applyDiscount(cost.price, cost.discount);
  } else {
    return utils.calculateFinalPrice(cost.price, cost.discount, quantity);
  }
}
export function applyDiscount(_ref6) {
  let {
    cost
  } = _ref6;
  if (isFixedPrice(cost) || isVariablePrice(cost)) {
    return utils.applyDiscount(cost.price, cost.discount);
  } else {
    return utils.applyDiscount(cost.maxPrice, cost.discount);
  }
}
export function minPriceFinal(_ref7) {
  let {
    cost
  } = _ref7;
  return cost.pricingType === ServiceCostType.Range && cost.minPrice ? utils.applyDiscount(cost.minPrice, cost.discount) : null;
}
export function period(service) {
  return isRecurring(service.billing) ? service.billing.recurrencePeriod : null;
}
export function changes(service) {
  return isAmended(service.status) ? service.status.amended.changes : isCompleted(service.status) ? service.status.completed.changes || null : null;
}

//
// testers
//
export function isRecurrencePeriod(service, period) {
  return isRecurring(service.billing) && service.billing.recurrencePeriod === period;
}
export function isBilledUpfront(service) {
  return isRecurring(service.billing) ? service.billing.isBilledUpfront : false;
}
export function isSkipBillingOnAcceptance(service) {
  return isRecurring(service.billing) && isOngoing(service.billing) ? service.billing.billUpfrontState.skipBillingOnAcceptance : false;
}
export function periodRequiresBillingDayOfWeek(period) {
  return [RecurrencePeriodTypes.Weekly, RecurrencePeriodTypes.BiWeekly].includes(period);
}
export const isYearlyService = service => isRecurring(service.billing) && service.billing.recurrencePeriod === RecurrencePeriodTypes.Yearly;
export const isQuarterlyService = service => isRecurring(service.billing) && service.billing.recurrencePeriod === RecurrencePeriodTypes.Quarterly;
export const isMonthlyService = service => isRecurring(service.billing) && service.billing.recurrencePeriod === RecurrencePeriodTypes.Monthly;
export const isWeeklyService = service => isRecurring(service.billing) && service.billing.recurrencePeriod === RecurrencePeriodTypes.Weekly;
export const isBiWeeklyService = service => isRecurring(service.billing) && service.billing.recurrencePeriod === RecurrencePeriodTypes.BiWeekly;
export function isAmended(status) {
  return status.type === ServiceStatusType.Amended;
}
export function isApproved(status) {
  return status.type === ServiceStatusType.Approved;
}
export function isAwaitingApproval(status) {
  return status.type === ServiceStatusType.AwaitingApproval;
}
export function requiresClientApproval(service) {
  return isAmended(service.status) || isAwaitingApproval(service.status);
}
export function isActive(service) {
  return isApproved(service.status) || isAmended(service.status);
}
export function isPaused(service) {
  return isOngoing(service.billing) && service.billing.isPaused;
}
export function isProrated(service) {
  return isOngoing(service.billing) && service.billing.isProrated;
}
export function hasAmendments(service) {
  const serviceChanges = changes(service);
  return !!serviceChanges && Object.keys(serviceChanges).some(k => serviceChanges[k] != null);
}
export function note(service) {
  return isAmended(service.status) ? service.status.amended.note : isAwaitingApproval(service.status) ? service.status.awaitingApproval.note : null;
}
export function approvalMethod(service) {
  return isAmended(service.status) ? service.status.amended.approvalMethod : isAwaitingApproval(service.status) ? service.status.awaitingApproval.approvalMethod || null : null;
}
export function hasAutoAmendments(service) {
  const apMethod = approvalMethod(service);
  return !!apMethod && isAutoApproval(apMethod);
}
export function hasApprovalMethod(service) {
  return !!approvalMethod(service);
}
export function autoApprovalDate(service) {
  var _apMethod$auto;
  const apMethod = approvalMethod(service);
  return apMethod === null || apMethod === void 0 || (_apMethod$auto = apMethod.auto) === null || _apMethod$auto === void 0 ? void 0 : _apMethod$auto.approveDate;
}
export function isMandatory(participation) {
  return participation.type === 'mandatory';
}
export function isOptional(participation) {
  return participation.type === 'optional';
}
export function isTerminated(status) {
  return status.type === ServiceStatusType.Terminated;
}
export function isCompleted(status) {
  return status.type === ServiceStatusType.Completed;
}
export function isCanceled(status) {
  return status.type === ServiceStatusType.Canceled;
}
export function effectiveDate(_ref8) {
  let {
    status
  } = _ref8;
  return isAwaitingApproval(status) || isCanceled(status) ? null : status.date;
}
export function amendmentDate(_ref9) {
  let {
    status
  } = _ref9;
  return isAwaitingApproval(status) ? status.awaitingApproval.addedOn : isAmended(status) ? status.amended.amendedOn : null;
}
export const priceIncrease = service => service.cost.priceIncrease;
export function nextYearPrice(service) {
  const priceIncrease = service.cost.priceIncrease;
  if (!priceIncrease || priceIncrease.percentIncrease.isZero() || priceIncrease.percentIncrease.isNegative()) {
    return null;
  }
  const rawResult = finalPrice(service, 1).times(priceIncrease.percentIncrease.plus(100)).div(100);
  switch (priceIncrease.roundPrices) {
    case RoundPrices.None:
      return rawResult.toDP(2).toNumber();
    case RoundPrices.Down1:
      return rawResult.floor().toNumber();
    case RoundPrices.Down5:
      return rawResult.minus(rawResult.mod(5)).toNumber();
    case RoundPrices.Down10:
      {
        return rawResult.minus(rawResult.mod(10)).toNumber();
      }
  }
}
export function integratedClassId(service) {
  return service.integrations.qboIntegratedClassId();
}
export function financialCentsIntegration(service) {
  return service.integrations.financialCentsIntegration();
}
export const karbonIntegration = service => service.integrations.karbonIntegration();