function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import * as invoiceIntegrationModel from "../invoice-integration";
import * as timeModel from "../time";
import * as serviceModel from "../service";
import { getDisplayedStatus } from "../../business-logic/invoiceLogic";
import Decimal from 'decimal.js';
import { utils } from "../../utils";
import { InvoiceStatusEnum } from 'anchor-scout/types';
import { MoneyModel } from "../MoneyModel";
export const fromScoutDTO = (isVendor, dto) => {
  const status = isVendor ? dto.vendorStatus : dto.customerStatus;
  return {
    id: dto.id,
    netTerms: dto.netTerms,
    createdOn: dto.createdOn,
    issuedOn: dto.issuedOn,
    dueDate: dto.dueDate,
    customerId: dto.customerId,
    isRetryable: dto.isRetryable,
    pdfExists: dto.pdfExists,
    totalAmount: dto.totalAmount,
    paymentMethodStatus: dto.paymentMethodStatus,
    formattedNumber: dto.formattedNumber,
    status: status.type,
    approvalMethod: status.type === InvoiceStatusEnum.RequiresPaymentApproval ? status.requiresPaymentApproval.approvalMethod : null,
    paymentFailure: status.type === InvoiceStatusEnum.PaymentFailed ? {
      code: status.paymentFailed.failureCode,
      message: status.paymentFailed.message
    } : null,
    paymentStop: status.type === InvoiceStatusEnum.PaymentStopped ? status.paymentStopped : null,
    paymentDisputed: status.type === InvoiceStatusEnum.PaymentDisputed ? {
      code: status.paymentDisputed.failureCode,
      message: status.paymentDisputed.message
    } : null,
    processingAction: status.type === InvoiceStatusEnum.PaymentActionRequired ? status.paymentActionRequired.processingAction : null,
    displayedStatus: dto.displayStatus,
    paymentStatus: isVendor ? dto.vendorPaymentDisplayStatus : dto.customerPaymentDisplayStatus,
    isZeroAmount: dto.isTotalAmountZero,
    clientContactId: '',
    integration: dto.integration,
    items: dto.items.map(item => _objectSpread(_objectSpread({}, item), {}, {
      serviceId: null,
      serviceTemplateId: null,
      adHocChargeId: null
    }))
  };
};
export const fromDTO = (dto, now) => {
  const dueDate = timeModel.fromDatestampObject(dto.dueDate);
  const items = dto.items.map(itemFromDTO);
  const totalAmount = items.reduce((acc, item) => acc.add(item.rawTotalAmount), new Decimal(0));
  const isZeroAmount = totalAmount.isZero();
  return {
    id: dto.id,
    netTerms: dto.netTerms,
    formattedNumber: dto.formattedNumber,
    status: dto.status,
    isRetryable: dto.isRetryable,
    paymentFailure: dto.paymentFailure,
    customerId: dto.customerId,
    clientContactId: dto.clientContactId,
    pdfExists: dto.pdfExists,
    paymentMethodStatus: dto.paymentMethodStatus,
    items,
    processingAction: dto.processingAction || null,
    paymentStatus: null,
    // currently unsupported as there isn't enough data to calculate it. Will be supported when using the scout DTO.
    approvalMethod: null,
    // currently unsupported as there isn't enough data to calculate it. Will be supported when using the scout DTO.
    paymentStop: dto.paymentStop,
    paymentDisputed: dto.paymentDisputed,
    createdOn: timeModel.formatDate(timeModel.fromDatestampObject(dto.createdOn)),
    issuedOn: timeModel.formatDate(dto.issuedOn ? timeModel.fromDatestampObject(dto.issuedOn) : timeModel.fromDatestampObject(dto.createdOn)),
    dueDate: timeModel.formatDate(dueDate),
    integration: dto.integration && invoiceIntegrationModel.fromDTO(dto.integration, isZeroAmount),
    totalAmount: utils.formatDollarAmount(totalAmount),
    isZeroAmount,
    displayedStatus: getDisplayedStatus(dto.status, dueDate, now)
  };
};
export function itemFromDTO(dto) {
  const rate = MoneyModel.fromDTO(dto.temp_rate);
  const discount = serviceModel.discountFromDTO(dto.discount);
  const quantity = dto.temp_quantity ? new Decimal(dto.temp_quantity).div(100) : null;
  const proRata = dto.temp_proRata ? new Decimal(dto.temp_proRata).div(100) : null;
  const totalAmount = utils.calculateFinalPrice(rate.amount(), discount, quantity || 1).mul(proRata || 1);
  return {
    id: dto.id,
    name: dto.name,
    note: dto.note,
    unitName: dto.unitName,
    serviceId: dto.serviceId,
    qbo: dto.qbo,
    serviceTemplateId: dto.serviceTemplateId,
    adHocChargeId: dto.adHocChargeId,
    quantity: quantity != null ? utils.formatNumberWithCommas(quantity) : '',
    rate: rate.display(),
    discount: discount ? discount.format({
      showValueAsNegative: true
    }) : '',
    totalAmount: utils.formatDollarAmount(totalAmount.toDP(2)),
    rawTotalAmount: totalAmount.toDP(2)
  };
}