function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import isEqual from 'lodash/isEqual';
import { notEmpty } from "../../infra/utils";
import { BillingTriggerType } from "../../types/api.model";
import { RecurrencePeriodTypes, ServiceChangeItemType } from "../service/model";
import { finalPrice, isAmended, isFixedPrice, isRangePrice, isVariablePrice } from "./selectors";
import { isBefore } from 'date-fns';
function nullablesAreNotEqual(nullable1, nullable2, equalFn) {
  if (nullable1 != null && nullable2 == null || nullable1 == null && nullable2 != null || nullable1 != null && nullable2 != null && !equalFn(nullable1, nullable2)) {
    return true;
  }
  return false;
}
function servicePricingChangeItem(service) {
  const currentCost = service.cost;
  if (isAmended(service.status) && service.status.amended.changes.cost != null) {
    const {
      cost: changedCost
    } = service.status.amended.changes;
    if (isRangePrice(changedCost) && isRangePrice(currentCost)) {
      if (nullablesAreNotEqual(changedCost.minPrice, currentCost.minPrice, (minPrice1, minPrice2) => minPrice1.equals(minPrice2)) || !changedCost.maxPrice.equals(currentCost.maxPrice)) return {
        type: ServiceChangeItemType.Pricing,
        requiresApproval: changedCost.maxPrice.greaterThan(currentCost.maxPrice) || changedCost.minPrice != null && (currentCost.minPrice == null || changedCost.minPrice.greaterThan(currentCost.minPrice)),
        value: changedCost
      };
    } else if (isFixedPrice(changedCost) && isFixedPrice(currentCost)) {
      if (!changedCost.price.equals(currentCost.price)) return {
        type: ServiceChangeItemType.Pricing,
        requiresApproval: changedCost.price.greaterThan(currentCost.price),
        value: changedCost
      };
    } else if (isVariablePrice(changedCost) && isVariablePrice(currentCost)) {
      if (nullablesAreNotEqual(changedCost.unitCap, currentCost.unitCap, (unitCap1, unitCap2) => unitCap1.equals(unitCap2)) || !changedCost.price.equals(currentCost.price) || changedCost.unitName !== currentCost.unitName) return {
        type: ServiceChangeItemType.Pricing,
        requiresApproval: changedCost.price.greaterThan(currentCost.price) || changedCost.unitName !== currentCost.unitName || currentCost.unitCap != null && (changedCost.unitCap == null || changedCost.unitCap.greaterThan(currentCost.unitCap)),
        value: changedCost
      };
    } else if (changedCost.pricingType !== currentCost.pricingType) {
      return {
        type: ServiceChangeItemType.Pricing,
        requiresApproval: true,
        value: changedCost
      };
    }
  }
  return null;
}
function serviceDiscountChangeItem(service) {
  if (isAmended(service.status) && service.status.amended.changes.cost != null && nullablesAreNotEqual(service.status.amended.changes.cost.discount, service.cost.discount, (discount1, discount2) => discount1.isSameAs(discount2))) {
    const updatedCost = service.status.amended.changes.cost;
    const origPrice = finalPrice(service, 1);
    const newPrice = finalPrice({
      cost: updatedCost
    }, 1);
    return {
      type: ServiceChangeItemType.Discount,
      requiresApproval: newPrice.greaterThan(origPrice),
      value: service.status.amended.changes.cost.discount
    };
  } else {
    return null;
  }
}
function isSingleTrigger(trigger) {
  switch (trigger) {
    case BillingTriggerType.OnApproval:
    case BillingTriggerType.OneTimeManual:
    case BillingTriggerType.OneTimeDelivery:
      return true;
    default:
      return false;
  }
}
function invoicesPerYear(period) {
  switch (period) {
    case RecurrencePeriodTypes.Weekly:
      return 52;
    case RecurrencePeriodTypes.BiWeekly:
      return 26;
    case RecurrencePeriodTypes.Monthly:
      return 12;
    case RecurrencePeriodTypes.Quarterly:
      return 4;
    case RecurrencePeriodTypes.Yearly:
      return 1;
  }
}
function isPeriodIncreased(before, after) {
  switch (before.trigger) {
    case BillingTriggerType.Ongoing:
    case BillingTriggerType.RepeatableManual:
      switch (after.trigger) {
        case BillingTriggerType.Ongoing:
        case BillingTriggerType.RepeatableManual:
          return invoicesPerYear(after.recurrencePeriod) > invoicesPerYear(before.recurrencePeriod);
        default:
          return false;
      }
    default:
      return false;
  }
}
function serviceBillingChangeItem(service) {
  if (isAmended(service.status) && service.status.amended.changes.billing != null && !isEqual(service.status.amended.changes.billing, service.billing)) {
    const originalBilling = service.billing;
    const changedBilling = service.status.amended.changes.billing;
    return {
      type: ServiceChangeItemType.BillingTrigger,
      requiresApproval: isSingleTrigger(originalBilling.trigger) && !isSingleTrigger(changedBilling.trigger) || isPeriodIncreased(originalBilling, changedBilling),
      value: changedBilling
    };
  } else {
    return null;
  }
}
function serviceDescriptionChangeItem(service) {
  if (isAmended(service.status) && service.status.amended.changes.description != null && service.description !== service.status.amended.changes.description) {
    return {
      type: ServiceChangeItemType.Description,
      requiresApproval: true,
      value: service.status.amended.changes.description
    };
  } else {
    return null;
  }
}
function servicePriceIncreaseChangeItem(service) {
  if (isAmended(service.status) && service.status.amended.changes.cost != null && !isEqual(service.status.amended.changes.cost.priceIncrease, service.cost.priceIncrease)) {
    const newValue = service.status.amended.changes.cost.priceIncrease;
    const oldValue = service.cost.priceIncrease;
    const addingPriceIncrease = !!newValue && !oldValue;
    const hasPriceIncrease = !!oldValue && !!newValue && newValue.percentIncrease.gt(oldValue.percentIncrease);
    const isSoonerPriceIncrease = !!(newValue !== null && newValue !== void 0 && newValue.increaseOn) && !!(oldValue !== null && oldValue !== void 0 && oldValue.increaseOn) && isBefore(newValue.increaseOn, oldValue.increaseOn);
    return {
      type: ServiceChangeItemType.PriceIncrease,
      requiresApproval: addingPriceIncrease || hasPriceIncrease || isSoonerPriceIncrease,
      value: newValue
    };
  } else {
    return null;
  }
}
export function makeServiceChangeList(service) {
  return [servicePricingChangeItem, serviceDiscountChangeItem, serviceDescriptionChangeItem, serviceBillingChangeItem, servicePriceIncreaseChangeItem].map(fn => fn(service)).filter(notEmpty);
}
export function serviceChangeListToChanges(changeList, service) {
  if (changeList.length === 0) return null;
  const changedPricing = changeList.find(_ref => {
    let {
      type
    } = _ref;
    return type === ServiceChangeItemType.Pricing;
  });
  const changedDiscount = changeList.find(_ref2 => {
    let {
      type
    } = _ref2;
    return type === ServiceChangeItemType.Discount;
  });
  const cost = changedPricing || changedDiscount ? _objectSpread(_objectSpread({}, (changedPricing === null || changedPricing === void 0 ? void 0 : changedPricing.value) || service.cost), {}, {
    discount: (changedDiscount === null || changedDiscount === void 0 ? void 0 : changedDiscount.value) || service.cost.discount
  }) : null;
  return Object.assign({
    name: null,
    note: null,
    cost,
    billing: null,
    description: null
  }, ...changeList.map(item => {
    switch (item.type) {
      case ServiceChangeItemType.BillingTrigger:
        return {
          billing: item.value
        };
      case ServiceChangeItemType.Description:
        return {
          description: item.value
        };
      default:
        return {};
    }
  }));
}